import React, { useState } from 'react';
import { NavigationMenu, NavigationMenuItem, NavigationMenuList } from '@/components/ui/navigation-menu';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { buttonVariants } from '@/components/ui/button';
import { Menu } from 'lucide-react';
import logo from '@/assets/icons/logo-horizontal.png';

const routeList = [
    {
        href: "#howItWorks",
        label: "How it works",
    },
    {
        href: "#pricing",
        label: "Pricing",
    },
    {
        href: "#faq",
        label: "FAQ",
    },
];

export const Navbar = ({ bookDemoUrl }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <header className="sticky border-b-[1px] top-0 z-40 w-full bg-white/75 backdrop-blur-md dark:border-b-slate-700 dark:bg-background">
            <NavigationMenu className="mx-auto">
                <NavigationMenuList className="container h-20 px-4 w-screen flex justify-between ">
                    <NavigationMenuItem className="font-weight-500 flex">
                        <a
                            rel="noreferrer noopener"
                            href="/"
                            className="mx-1 font-bold text-xl flex"
                        >
                            <img src={logo} alt="Logo" width="180" />
                        </a>
                    </NavigationMenuItem>

                    {/* mobile */}
                    <span className="flex md:hidden">
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger className="px-2">
                <Menu
                    className="flex md:hidden h-5 w-5"
                    onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Menu Icon</span>
                </Menu>
              </SheetTrigger>

              <SheetContent side={"left"}>
                <SheetHeader>
                  <SheetTitle className="font-weight-500 text-xl">
                    bestShot.ai
                  </SheetTitle>
                </SheetHeader>
                <nav className="flex flex-col justify-center items-center gap-2 mt-4">
                  {routeList.map(({ href, label }) => (
                      <a
                          rel="noreferrer noopener"
                          key={label}
                          href={href}
                          onClick={() => setIsOpen(false)}
                          className={buttonVariants({ variant: "ghost" })}
                      >
                          {label}
                      </a>
                  ))}
                    <a
                        href="/login"
                        className={`border ${buttonVariants({variant: "outline"})}`}
                    >
                        Login
                    </a>
                    <a
                        href={bookDemoUrl}
                        rel='noopener noreferrer'
                        target="_blank"
                        className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-white font-weight-300 py-2 px-4 rounded-lg shadow-md hover:from-indigo-500 hover:to-indigo-900 transition-transform duration-200"
                    >
                        📅 Book a demo
                    </a>
                </nav>
              </SheetContent>
            </Sheet>
          </span>
                    {/* desktop */}
                    <nav className="hidden md:flex gap-2">
                        {routeList.map((route, i) => (
                            <a
                                rel="noreferrer noopener"
                                href={route.href}
                                key={i}
                                className={`text-[1.12rem] ${buttonVariants({
                                    variant: "ghost",
                                })}`}
                            >
                                {route.label}
                            </a>
                        ))}
                    </nav>

                    <div className="hidden md:flex gap-2">
                        <a
                            href="/login"
                            className={`border ${buttonVariants({ variant: "outline" })}`}
                        >
                            Login
                        </a>
                        <a
                            href={bookDemoUrl}
                            rel='noopener noreferrer'
                            target="_blank"
                            className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-white font-weight-300
                                py-2 px-4 rounded-lg shadow-md hover:from-indigo-500 hover:to-indigo-900
                                transition-transform duration-200"
                        >
                            📅 Book a demo
                        </a>
                    </div>
                </NavigationMenuList>
            </NavigationMenu>
        </header>
    );
};