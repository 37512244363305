import React, {useState, useEffect} from 'react';
import { Button } from '@/components/ui/button';
import { ChevronUp } from 'lucide-react';


const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0, behavior: "smooth",
        });
    };

    return (
        isVisible && (
            <Button
                onClick={scrollToTop}
                className="fixed bottom-5 right-5 z-50 bg-primary text-white rounded-full shadow-lg hover:bg-indigo-700
                            transition-opacity h-16 w-16 flex flex-col items-center justify-center pb-4"
            >
                <ChevronUp className="h-8 w-8 mb-0"/> {/* Reduce margin below the icon */}
                <span className="leading-none">Top</span>
            </Button>
        )
    );
};

export default ScrollToTopButton;