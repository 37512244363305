import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Check } from 'lucide-react';

const PopularPlanType = { NO: 0,  YES: 1,};

const pricingList = [
    {
        title: "Free",
        popular: PopularPlanType.NO,
        price: "€0",
        priceFrom: false,
        pricePer: null,
        description:
            "Kick off your idea or test out our app.",
        buttonText: "Get started",
        benefitList: [
            "1 business member",
            "Up to 1k photos / month",
        ],
    },
    {
        title: "Premium",
        popular: PopularPlanType.YES,
        price: "€7",
        pricePer: "1k photos processed",
        priceFrom: true,
        description:
            "Ideal for small and medium-sized businesses.",
        buttonText: "Start free trial",
        benefitList: [
            "Unlimited business members",
            "Up to 100k photos / month",
            "Private / Public Event Gallery",
            "Priority support"
        ],
    },
    {
        title: "Enterprise",
        popular: PopularPlanType.NO,
        price: null,
        priceFrom: false,
        pricePer: null,
        description:
            "A flexible plan tailored for large businesses",
        buttonText: "Contact us",
        benefitList: [
            "Unlimited business members",
            "Unlimited photos / month",
            "Private / Public Event Gallery",
            "Priority support",
            "Suitable for large businesses",
        ],
    },
];

export const Pricing = () => {
    return (
        <section
            id="pricing"
            className="container py-10 sm:py-20"
        >
            <h2 className="text-2xl md:text-4xl font-weight-500 text-center">
                Choose the right
                <span className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-transparent bg-clip-text">
                    &nbsp;Plan&nbsp;
                </span>
                for your business.
            </h2>
            <h3 className="text-xl text-center text-muted-foreground pt-4 pb-8">
                From spinning off your idea to hosting a successful event, bestShot.ai is the perfect solution.
            </h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {pricingList.map((pricing) => (
                    <Card
                        key={pricing.title}
                        className={
                            pricing.popular === PopularPlanType.YES
                                ? "drop-shadow-xl shadow-black/10 dark:shadow-white/10"
                                : ""
                        }
                    >
                        <CardHeader>
                            <CardTitle className="flex flex-col sm:flex-row items-top justify-between mb-4 font-weight-500">
                                {pricing.title}
                                {pricing.popular === PopularPlanType.YES ? (
                                    <Badge
                                        variant="secondary"
                                        className="text-xs w-24 justify-center mt-2 sm:mt-0 text-primary px-2 text-primary bg-indigo-100/90 hover:bg-indigo-100/90"
                                    >
                                        Most popular
                                    </Badge>
                                ) : null}
                            </CardTitle>
                            <div>
                                {pricing.price ?
                                    <>
                                        {pricing.priceFrom && <span className="text-muted-foreground">from&nbsp;</span>}
                                        <span className="text-3xl font-weight-400">{pricing.price}</span>
                                        {pricing.pricePer && <span className="font-weight-600"> / {pricing.pricePer}</span>}
                                    </>
                                    :
                                    <span className="text-2xl font-weight-400">Contact us</span>
                                }
                            </div>

                            <CardDescription>{pricing.description}</CardDescription>
                        </CardHeader>

                        <CardContent>
                            <Button className="w-full" asChild>
                                {pricing.price ?
                                    <Link to="/signup" rel='noopener noreferrer'>{pricing.buttonText}</Link>
                                    :
                                    <a href="mailto:support@bestshot.ai">{pricing.buttonText}</a>
                                }
                            </Button>

                        </CardContent>

                        <hr className="w-4/5 m-auto mb-4" />

                        <CardFooter className="flex">
                            <div className="space-y-4">
                                {pricing.benefitList.map((benefit) => (
                                    <span
                                        key={benefit}
                                        className="flex"
                                    >
                                    <Check className="text-green-500" />{" "}
                                        <h3 className="ml-2">{benefit}</h3>
                                    </span>
                                ))}
                            </div>
                        </CardFooter>
                    </Card>
                ))}
            </div>
        </section>
    );
};