import React from 'react';


const GalleryPhotoCard = ({ photo, index, openLightbox }) => {
    const fileName = photo.file_name.split(".")[0];
    return (
        <div className="relative group p-1 hover:scale-[1.01]" onClick={() => openLightbox(index)}>
            <img
                key={photo.id}
                src={photo.file}
                alt={fileName}
                className="w-full h-auto rounded-sm shadow-md"
            />
            <div
                className="absolute bottom-0 left-0 w-full h-full flex items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-1">
                <div className="w-full bg-black bg-opacity-60 p-2">
                    <p className="text-white text-xs text-center">{fileName}</p>
                </div>
            </div>
        </div>
    );
};

export default GalleryPhotoCard;
