import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Button } from '@/components/ui/button';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';

const DateRangePicker = ({ date, handleDateChange }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef(null);
    const buttonRef = useRef(null);

    const handlePopoverToggle = () => {
        setIsPopoverOpen(prev => !prev);
    };

    const handleClickOutside = (e) => {
        if (popoverRef.current && !popoverRef.current.contains(e.target) && !buttonRef.current.contains(e.target)) {
            setIsPopoverOpen(false);
        }
    };

    useEffect(() => {
        if (isPopoverOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isPopoverOpen]);

    const handleDoneClick = () => {
        setIsPopoverOpen(false);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Button
                ref={buttonRef}
                id="dates"
                variant={"outline"}
                className={`w-full justify-start text-left font-normal ${
                    !date && 'text-muted-foreground'
                }`}
                onClick={handlePopoverToggle}
            >
                <CalendarIcon className="mr-2 h-4 w-4"/>
                {date?.from ? (
                    date?.to && !moment(date.from).isSame(date.to) ? (
                        <>
                            {moment(date?.from).format("DD MMMM YYYY")}
                            &nbsp;&minus;&nbsp;
                            {moment(date?.to).format("DD MMMM YYYY")}
                        </>
                    ) : (
                        moment(date?.from).format("DD MMMM YYYY")
                    )
                ) : (
                    <span>Pick your event date(s)</span>
                )}
            </Button>

            {isPopoverOpen && (
                <div
                    ref={popoverRef}
                    className="absolute z-50 bg-white shadow-lg border rounded-md mt-2"
                >
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={handleDateChange}
                        numberOfMonths={1}
                    />

                    <div className="pb-2 flex justify-center">
                        <Button variant="outline" onClick={handleDoneClick}>
                            Done
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;