import React from 'react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import heroVideo from '@/assets/bestshotai-hero-video.mp4';

export const Hero = ({ bookDemoUrl }) => {
    return (
        <section className="container grid lg:grid-cols-2 place-items-center py-10 md:py-20 gap-10">
            <div className="text-center lg:text-start space-y-6">
                <main className="text-2xl sm:text-4xl md:text-6xl font-weight-500">
                    <h1 className="inline leading-[2rem] md:leading-[5rem]">
                        Leverage
                        <h2 className="inline z-100">
                            <span
                                className="inline-flex items-center justify-center text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 via-purple-600 to-indigo-900">
                                &nbsp;AI&nbsp;
                            </span>
                        </h2>
                        to organize your event photos&nbsp;
                        <h2 className="inline lg:block">
                            <span className="inline bg-gradient-to-r from-indigo-400 to-indigo-900 text-transparent bg-clip-text">
                                effortlessly.
                            </span>
                        </h2>
                    </h1>
                </main>

                <p className="text-md sm:text-lg md:text-xl text-muted-foreground md:w-10/12 mx-auto lg:mx-0">
                    With <b>bestShot.ai</b>, you can effortlessly manage and share photo collections, ensuring a seamless event experience.
                </p>

                <div className="space-y-4 space-x-2 md:space-y-1 md:space-x-4">
                    <Button className="mt-4 mb-4 w-56 h-10 text-lg" asChild>
                        <Link to={bookDemoUrl} rel='noopener noreferrer' target="_blank">
                            📅&nbsp;Book a demo
                        </Link>
                    </Button>

                    <Button className="mt-4 mb-4 w-56 h-10 text-lg" variant='outline' asChild>
                        <Link to="/signup" rel='noopener noreferrer'>Get started</Link>
                    </Button>
                </div>
            </div>

            <div className="w-full h-full flex items-start rounded-lg overflow-hidden shadow-xl bg-gray-100">
                <video
                    src={heroVideo}
                    className="w-full h-full block object-top"
                    autoPlay
                    loop
                    muted
                    playsInline
                />
            </div>

            <div className="shadow"></div>
        </section>
    );
};