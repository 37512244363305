import React from 'react';
import { Navbar } from '@/components/Landing/Navbar';
import { Hero } from '@/components/Landing/Hero';
import { HowItWorks } from '@/components/Landing/HowItWorks';
import { Cta } from '@/components/Landing/Cta';
import { Pricing } from '@/components/Landing/Pricing';
import { Faq } from '@/components/Landing/Faq';
import Footer from '@/containers/Footer';

const Landing = () => {
    const bookDemoUrl = 'https://calendar.app.google/FHtScM3SXBk5EU2P7'
    return (
        <>
            <Navbar bookDemoUrl={bookDemoUrl}/>
            <Hero bookDemoUrl={bookDemoUrl}/>
            <HowItWorks />
            <Cta bookDemoUrl={bookDemoUrl}/>
            <Pricing />
            <Faq />
            <Footer />
        </>
    );
};

export default Landing;