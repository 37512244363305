import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

export const Cta = function Cta({ bookDemoUrl }) {
  return (
    <section id="cta" className="bg-indigo-100/90 py-12 my-10 sm:my-20">
      <div className="container lg:grid lg:grid-cols-2 place-items-center">
        <div className="lg:col-start-1">
          <h2 className="text-2xl md:text-4xl font-weight-500">
            Your
            <span className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-transparent bg-clip-text">
              &nbsp;Intelligent&nbsp;
            </span>
            way to boost sales.
          </h2>
          <p className="text-muted-foreground text-xl mt-4 mb-8 lg:mb-0">
            Use state of the art <b>Artificial Intelligence</b> technology, to gain advantage against competitors and drive more sales.
          </p>
        </div>

        <div className="space-y-4 lg:col-start-2">
          <Button className="w-56 h-10 mr-4 text-lg" asChild>
              <Link to={bookDemoUrl} rel='noopener noreferrer' target="_blank">📅&nbsp;Book a demo</Link>
          </Button>

          <Button className="w-56 h-10 text-lg" variant='outline' asChild>
            <Link to="/signup" rel='noopener noreferrer'>Get started</Link>
          </Button>
        </div>
      </div>
    </section>
  );
};