import React from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

const FAQList = [
    {
        question: "How does AI improve photo organization?",
        answer: "bestShot.ai uses advanced face recognition to group photos by individuals, making it effortless for attendees to find their images.",
        value: "item-1",
    },
    {
        question: "Is my data secure?",
        answer: "Absolutely! bestShot.ai employs enterprise-grade encryption and secure storage for all your photos.",
        value: "item-2",
    },
    {
        question: "Can I sell event photos directly?",
        answer: "Yes, we’re working on it! Soon, you’ll be able to sell high-resolution downloads or prints online.",
        value: "item-3",
    },
    {
        question: "What if I need help?",
        answer: "Our team is always ready to help with setup, troubleshooting, or any feature customization.",
        value: "item-4",
    },
];

export const Faq = function Faq() {
    return (
        <section id="faq" className="container py-10 sm:py-20">
            <h2 className="text-2xl md:text-4xl font-weight-500 mb-4">
                Frequently Asked
                <span className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-transparent bg-clip-text">
          &nbsp;Questions&nbsp;
        </span>
            </h2>

            <Accordion type="single" collapsible className="w-full AccordionRoot">
                {FAQList.map(({ question, answer, value }) => (
                    <AccordionItem key={value} value={value}>
                        <AccordionTrigger className="text-left">{question}</AccordionTrigger>
                        <AccordionContent>{answer}</AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>

            <h3 className="font-medium mt-4">
                Still have questions?{" "}
                <a
                    rel="noreferrer noopener"
                    href="mailto:support@bestshot.ai"
                    className="text-primary transition-all border-primary hover:border-b-2"
                >
                    Contact us
                </a>
            </h3>
        </section>
    );
};