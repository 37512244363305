import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { CloudUpload, CalendarPlus, WandSparkles, CloudDownload} from 'lucide-react';

const features = [
    {
        icon: <CalendarPlus className="w-10 h-10 text-primary" />,
        title: "Create your event",
        description:
            "Set up your event on bestShot.ai."
    },
    {
        icon: <CloudUpload className="w-10 h-10 text-primary" />,
        title: "Upload event photos",
        description:
            "Upload your event photos to our system."
    },
    {
        icon: <WandSparkles className="w-10 h-10 text-primary" />,
        title: "AI Magic",
        description:
            "Let Artificial Intelligence process and organize your photos"
    },
    {
        icon: <CloudDownload className="w-10 h-10 text-primary" />,
        title: "Download photos",
        description:
            "Download your grouped photos to your computer.",
    },
];

export const HowItWorks = () => {
    return (
        <section
            id="howItWorks"
            className="container text-center py-10 md:py-20"
        >
            <h2 className="text-2xl md:text-4xl font-weight-500">
                <span className="block sm:inline">
                    How it
                    <span className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-transparent bg-clip-text">
                        &nbsp;Works?&nbsp;
                    </span>
                </span>
                <span className="block sm:inline">
                    It's
                    <span className="bg-gradient-to-r from-indigo-400 to-indigo-900 text-transparent bg-clip-text">
                        &nbsp;Simple!&nbsp;
                    </span>
                </span>
            </h2>
            <p className="md:w-3/4 mx-auto mt-4 mb-8 text-xl text-muted-foreground">
                Organizing your event photos has never been easier. Create your fist event and get started in minutes.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {features.map(({ icon, title, description }) => (
                    <Card
                        key={title}
                        className="bg-indigo-100/20"
                    >
                        <CardHeader>
                            <CardTitle className="grid gap-4 place-items-center font-weight-500">
                                {icon}
                                {title}
                            </CardTitle>
                        </CardHeader>
                        <CardContent>{description}</CardContent>
                    </Card>
                ))}
            </div>
        </section>
    );
};